<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'OrderAdminListManager',

  components: {
    BuyerOrderAdminList: () => import('./BuyerOrderAdminList'),
    SuperOrderAdminList: () => import('./SuperOrderAdminList'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperOrderAdminList';
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerOrderAdminList';
      }
    },
  },
};
</script>
